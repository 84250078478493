import { RootState, store} from '../../Redux';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Configs from "src/Config";
import { logOut } from '../features/common/auth/authSlice';
import { useDispatch } from 'react-redux';

 

const BASE_URL = Configs.PUBLIC_API_HOST;

const baseQuery= fetchBaseQuery({
   baseUrl: BASE_URL,
   prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      const isAuth= (getState()as RootState).auth.isAuthenticated;
      // console.log('@@@@@@' ,getState())

      if (token) {
         headers.set('authorization', `Bearer ${token}`);
      }

      // if(!isAuth){
      //    // localStorage.removeItem('persist:auth')
      //    // window.location.reload()
      //    // window.location.href = '/login';
      // }

      return headers;
   },
 validateStatus : (response,result)=> {
   // console.log(response)
   // console.log(result)
   if (response.status === 401) {
      console.log('Unauthorized. Logging out...');
      // dispatch(logOut());
  }
   return response.status === 200 && !result.isError;
 }
 
});

export const baseApi = createApi({
   baseQuery,
   keepUnusedDataFor: 0,
   endpoints: () => ({}),

});
