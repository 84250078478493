import loadable from "@loadable/component";
import { ComponentType } from "react";
import { Fallback } from "../Components";

const Profile = loadable(() => import("../Views/Admin/Profile"), {
  fallback: <Fallback subtitle="Loading data..." />,
});

const DashBoard = loadable(() => import("../Views/Admin/DashBoard"), {
  fallback: <Fallback subtitle="Loading data..." />
});

const CaseLoad = loadable(() => import("../Views/Admin/CaseLoads/CaseLoads"), {
  fallback: <Fallback subtitle="Loading data..." />
});

const DirectorList = loadable(() => import("../Views/Admin/DirectorList"), {
  fallback: <Fallback subtitle="Loading data..." />
});

const TherapistList = loadable(() => import("../Views/Admin/TherapistList"), {
  fallback: <Fallback subtitle="Loading data..." />
});

const Notes = loadable(() => import("../Views/Admin/Notes/Notes"), {
  fallback: <Fallback subtitle="Loading data..." />
});

const Reports = loadable(() => import("../Views/Admin/Reports"), {
  fallback: <Fallback subtitle="Loading data..." />
});

const Notifications = loadable(() => import("../Views/Admin/Notifications"), {
  fallback: <Fallback subtitle="Loading data..." />
});

const Appointments = loadable(() => import("../Views/Admin/Appointment/Appointments"), {
  fallback: <Fallback subtitle="Loading data..." />
});

const AppointmentNotePage = loadable(() => import("../Views/Admin/Notes/AppointmentNotePage"), {
  fallback: <Fallback subtitle="Loading data..." />
});

const StudentProfile = loadable(() => import("../Views/Users/StudentDetails/StudentProfile"), {
  fallback: <Fallback subtitle="Loading data..." />
});

const ImportStudent = loadable(() => import("../Views/Admin/CaseLoads/ImportStudent"), {
  fallback: <Fallback subtitle="Loading data..." />
});

const ServiceLog = loadable(() => import("../Views/Admin/ServiceLog/ServiceLog"), {
  fallback: <Fallback subtitle="Loading data..." />
});

const Assessments = loadable(() => import("../Views/Admin/Assesments/Assesments"), {
  fallback: <Fallback subtitle="Loading data..." />
});

const AssessmentNote = loadable(() => import("../Views/Admin/Notes/AssesmentsNote"), {
  fallback: <Fallback subtitle="Loading data..." />
});

const AssessmentNoteList = loadable(() => import("../Views/Admin/Assesments/AssessmentsReportList"), {
  fallback: <Fallback subtitle="Loading data..." />
});

const AssignStudent = loadable(() => import("../Views/Admin/AssignStudent"), {
  fallback: <Fallback subtitle="Loading data..." />
});

const Meetings = loadable(() => import("../Views/Admin/Meetings/Meeting"), {
  fallback: <Fallback subtitle="Loading data..." />
});

const Timesheet = loadable(() => import("../Views/Admin/Timesheet/Timesheet"), {
  fallback: <Fallback subtitle="Loading data..." />
});

const InvoiceGenerator = loadable(() => import("../Views/Admin/Invoice/InvoiceGenerator/InvoiceGenerator"), {
  fallback: <Fallback subtitle="Loading data..." />
});

const AdminInvoiceSettings = loadable(() => import("../Views/Admin/Invoice/Settings/InvoiceSettings"), {
  fallback: <Fallback subtitle="Loading data..." />
});

const Invoice = loadable(() => import("../Views/Admin/Invoice/InvoiceList"), {
  fallback: <Fallback subtitle="Loading data..." />
});

export interface RouteInterface {
  path: string;
  exact?: boolean;
  name: string;
  component: ComponentType;
}

export const AdminRoutes: Array<RouteInterface> = [
  { path: "/admin/home", exact: true, name: "DashBoard", component: DashBoard },

  { path: "/admin/notes", exact: true, name: "Notes", component: Notes },

  {
    path: "/admin/profile",
    exact: true,
    name: "Profile",
    component: Profile,
  },

  {
    path: "/admin/caseload",
    exact: true,
    name: "CaseLoad",
    component: CaseLoad,
  },
 
  {
    path: "/admin/students/profile",
    exact: true,
    name: "StudentProfile",
    component: StudentProfile,
  },
 
  {
    path: "/admin/directors",
    exact: true,
    name: "DirectorList",
    component: DirectorList,
  },
 
  {
    path: "/admin/therapists",
    exact: true,
    name: "TherapistList",
    component: TherapistList,
  },

  {
    path: "/admin/reports",
    exact: true,
    name: "Reports",
    component: Reports,
  },

  {
    path: "/admin/invoice",
    exact: true,
    name: "Invoice",
    component: Invoice,
  },

  {
    path: "/admin/notifications",
    exact: true,
    name: "Notifications",
    component: Notifications,
  },

  {
    path: "/admin/appointments",
    exact: true,
    name: "Appointments",
    component: Appointments,
  },

  {
    path: "/admin/create-note",
    exact: true,
    name: "AppointmentNotePage",
    component: AppointmentNotePage,
  },

  {
    path: "/admin/student/import-students",
    exact: true,
    name: "ImportStudent",
    component: ImportStudent,
  },

  {
    path: "/admin/service-log",
    exact: true,
    name: "ServiceLog",
    component: ServiceLog,
  },

  {
    path: "/admin/assessments",
    exact: true,
    name: "Assessments",
    component: Assessments,
  },

  {
    path: "/admin/assessments-note",
    exact: true,
    name: "AssessmentNote",
    component: AssessmentNote,
  },

  {
    path: "/admin/assessments-report",
    exact: true,
    name: "AssessmentNoteList",
    component: AssessmentNoteList,
  },

  {
    path: "/admin/assign-student",
    exact: true,
    name: "AssignStudent",
    component: AssignStudent,
  },

  {
    path: "/admin/meeting",
    exact: true,
    name: "Meetings",
    component: Meetings,
  },

  {
    path: "/admin/timesheet",
    exact: true,
    name: "Timesheet",
    component: Timesheet
  },

  {
    path: "/admin/generate-invoice",
    exact: true,
    name: "InvoiceGenerator",
    component: InvoiceGenerator
  },

  {
    path: "/admin/invoice-setting",
    exact: true,
    name: "AdminInvoiceSettings",
    component: AdminInvoiceSettings
  },

  {
    path: "/admin/invoices",
    exact: true,
    name: "Invoices",
    component: Invoice
  }
];
