import {
  AppointmentIcon,
  AssessmentIcon,
  DashboardIcon,
  DirectorIcon,
  InvoiceIcon,
  MapIcon,
  NotebookIcon,
  ReportIcon,
  SettingIcon,
  NotificationIcon,
  TherapistIcon,
  LogIcon,
  SetCalenderIcon,
  MeetingIcon,
  TimesheetIcon,
  InvoiceSettingIcon,
  AssessmentReportIcon,
} from "src/Components/Icons";

import type { RoleModel } from "src/Models/Roles.models";

export class Menu {
  public title!: string;
  public pathname?: string;
  public children?: Array<Menu>

  // public icon?: (props: SvgIconProps) => void;
  // eslint-disable-next-line no-undef
  [key: string]: any;
}

export const HeaderMenu: Array<Menu> = [
  { title: "Appointments", pathname: "/appointments", icon: AppointmentIcon },
  { title: "Notes", pathname: "/notes", icon: NotebookIcon },
]


//  Placing first in the Sequence
const commonInitialMenuItems: Menu[] = [
  {
    title: "Case Load",
    pathname: "/caseload",
    icon: MapIcon,
  }
];

// Placing last in the Sequence
const commomFinalMenuItems: Menu[] = [
  { title: "Notes", pathname: "/notes", icon: NotebookIcon },
  { title: "Service Log", pathname: "/service-log", icon: LogIcon },
];

export const menuItems: { [key in RoleModel]: Menu[] } = {

  superadmin: [
    ...commonInitialMenuItems,
    { title: "Directors", pathname: "/directors", icon: DirectorIcon },
    { title: "Therapists", pathname: "/therapists", icon: TherapistIcon },
    {
      title: "Calendar", pathname: "", icon: SetCalenderIcon, children: [
        { title: "Meeting", pathname: "/meeting", icon: MeetingIcon },
        { title: "Appointments", pathname: "/appointments", icon: AppointmentIcon },
        { title: "Assessments", pathname: "/assessments", icon: AssessmentIcon },
        { title: "Assessments Report", pathname: "/assessments-report", icon: AssessmentReportIcon },
      ]
    },
    { title: "Reports", pathname: "/reports", icon: ReportIcon },
    ...commomFinalMenuItems,
    { title: "Timesheet", pathname: "/timesheet", icon: TimesheetIcon },
    { title: "Invoices", pathname: "/invoices", icon: InvoiceIcon },
    {
      title: "Settings", pathname: "", icon: SettingIcon, children: [
        { title: "Invoice Setting", pathname: "/invoice-setting", icon: InvoiceSettingIcon },
      ]
    },
  ],

  therapist: [
    ...commonInitialMenuItems,
    {
      title: "Calendar", pathname: "", icon: SetCalenderIcon, children: [
        { title: "Meeting", pathname: "/meeting", icon: MeetingIcon },
        { title: "Appointments", pathname: "/appointments", icon: AppointmentIcon },
        { title: "Assessments", pathname: "/assessments", icon: AssessmentIcon },
        { title: "Assessments Report", pathname: "/assessments-report", icon: AssessmentReportIcon },
      ]
    },
    { title: "Reports", pathname: "/reports", icon: ReportIcon },
    ...commomFinalMenuItems,
    { title: "Timesheet", pathname: "/timesheet", icon: TimesheetIcon },

  ],

  director: [
    ...commonInitialMenuItems,
    { title: "Therapists", pathname: "/therapists", icon: TherapistIcon },
    ...commomFinalMenuItems
  ],

  pta: [
    ...commonInitialMenuItems,
    {
      title: "Calendar", pathname: "", icon: SetCalenderIcon, children: [
        { title: "Meeting", pathname: "/meeting", icon: MeetingIcon },
        { title: "Appointments", pathname: "/appointments", icon: AppointmentIcon },
      ]
    },
    { title: "Reports", pathname: "/reports", icon: ReportIcon },
    ...commomFinalMenuItems,
    { title: "Timesheet", pathname: "/timesheet", icon: TimesheetIcon },
  ],
};
