import { useTheme } from "@mui/material/styles";
import SVGContainer from "./SVGContainer";

interface SvgProps {
  width?: number;
  height?: number;
  iconColor?: string;
}

function AssessmentReportIcon({
  width = 27,
  height = 27,
  iconColor,
  ...restProps
}: SvgProps) {
  const theme = useTheme();

  const color = iconColor || "#ffffff";
  return (
    <SVGContainer {...restProps}>
      <svg color={color} fill="currentColor" width={width} height={height} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><path d="M39.3,32.1H60.7a2,2,0,0,0,2-2v-4a6,6,0,0,0-5.8-6H43.3a5.91,5.91,0,0,0-5.8,6v4A1.74,1.74,0,0,0,39.3,32.1Z" /><path d="M71.4,25.2h-2a.94.94,0,0,0-1,1v4a7.9,7.9,0,0,1-7.8,7.9H39.3a7.9,7.9,0,0,1-7.8-7.9v-4a.94.94,0,0,0-1-1h-2a5.91,5.91,0,0,0-5.8,6V73.8a6,6,0,0,0,5.8,6H71.4a5.91,5.91,0,0,0,5.8-6V31.2A5.79,5.79,0,0,0,71.4,25.2ZM53,59.4h0v1.1a5.45,5.45,0,0,1-3.1,1,5.6,5.6,0,0,1,0-11.2,5.59,5.59,0,0,1,5.6,5.2A4.48,4.48,0,0,0,53,59.4ZM68,73.5a1.52,1.52,0,0,1-1.5,1.4h-9a1.43,1.43,0,0,1-1.4-1.4V72.1a1.43,1.43,0,0,1,1.4-1.4h9.1A1.43,1.43,0,0,1,68,72.1Zm0-6.3a1.52,1.52,0,0,1-1.5,1.4h-9a1.43,1.43,0,0,1-1.4-1.4V65.8a1.43,1.43,0,0,1,1.4-1.4h9.1A1.43,1.43,0,0,1,68,65.8Zm0-6.4a1.52,1.52,0,0,1-1.5,1.4h-9a1.43,1.43,0,0,1-1.4-1.4V59.4A1.43,1.43,0,0,1,57.5,58h9.1A1.43,1.43,0,0,1,68,59.4Zm.1-5.7a3.4,3.4,0,0,0-1.4-.2H63.8v-3a4,4,0,0,1-4-4H40a4,4,0,0,1-4,4v8.4a4,4,0,0,1,4,4H53.2a3.4,3.4,0,0,0-.2,1.4v1.4a6.64,6.64,0,0,0,.3,1.5h-18a3.37,3.37,0,0,1-3.4-3.4V47.1a3.37,3.37,0,0,1,3.4-3.4H64.7a3.37,3.37,0,0,1,3.4,3.4Z" /></svg>
    </SVGContainer>
  );
}

export default AssessmentReportIcon;
